import { Box, Drawer, Link, Theme, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import { useEffect } from 'react'
import toast from 'react-hot-toast'
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/use-auth'

interface MainSidebarProps {
    onClose?: () => void
    open?: boolean
}

const MainSidebarLink = styled(Link)(({ theme }) => ({
    borderRadius: theme.shape.borderRadius,
    display: 'block',
    padding: theme.spacing(1.5),
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
}))

export const MainSidebar: FC<MainSidebarProps> = (props) => {
    const { onClose, open } = props
    const { user, ...auth } = useAuth()
    const navigate = useNavigate()
    const location = useLocation()
    const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))

    const handleLogout = async (): Promise<void> => {
        try {
            onClose?.()
            await auth.logout()
            navigate('/')
        } catch (err) {
            console.error(err)
            toast.error('Unable to logout.')
        }
    }

    const handlePathChange = () => {
        if (open) {
            onClose?.()
        }
    }

    useEffect(handlePathChange, [location.pathname])

    return (
        <Drawer
            anchor="right"
            onClose={onClose}
            open={!lgUp && open}
            PaperProps={{ sx: { width: 256 } }}
            sx={{
                zIndex: (theme) => theme.zIndex.appBar + 100,
            }}
            variant="temporary"
        >
            <Box sx={{ p: 2 }}>
                {!auth.isAuthenticated && (
                    <>
                        <Link component={RouterLink} to="/authentication/login">
                            <MainSidebarLink
                                color="primary"
                                underline="none"
                                variant="subtitle2"
                            >
                                Login
                            </MainSidebarLink>
                        </Link>
                        <Link
                            component={RouterLink}
                            to="/authentication/register"
                        >
                            <MainSidebarLink
                                color="primary"
                                underline="none"
                                variant="subtitle2"
                            >
                                Register
                            </MainSidebarLink>
                        </Link>
                    </>
                )}
                {auth.isAuthenticated && (
                    <>
                        <Link component={RouterLink} to="/orders">
                            <MainSidebarLink
                                color="primary"
                                underline="none"
                                variant="subtitle2"
                            >
                                Orders
                            </MainSidebarLink>
                        </Link>
                        {user.role === 'Admin' && (
                            <Link component={RouterLink} to="/customers">
                                <MainSidebarLink
                                    color="primary"
                                    underline="none"
                                    variant="subtitle2"
                                >
                                    Customers
                                </MainSidebarLink>
                            </Link>
                        )}
                        <Box sx={{ cursor: 'pointer' }} onClick={handleLogout}>
                            <MainSidebarLink
                                color="primary"
                                underline="none"
                                variant="subtitle2"
                            >
                                Logout
                            </MainSidebarLink>
                        </Box>
                    </>
                )}
            </Box>
        </Drawer>
    )
}

MainSidebar.propTypes = {
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
