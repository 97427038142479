import LogoutIcon from '@mui/icons-material/Logout'
import {
    Box,
    Divider,
    ListItemIcon,
    ListItemText,
    MenuItem,
    Popover,
    Typography,
} from '@mui/material'
import PropTypes from 'prop-types'
import type { FC } from 'react'
import toast from 'react-hot-toast'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { useAuth } from '../hooks/use-auth'
import { Cog as CogIcon } from '../icons/cog'
import { EyeOff as EyeIcon } from '../icons/eye-off'

interface AccountPopoverProps {
    anchorEl: null | Element
    onClose?: () => void
    open?: boolean
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
    const { anchorEl, onClose, open, ...other } = props
    const navigate = useNavigate()
    const { user, impersonator, logout, impersonateStop } = useAuth()

    const handleLogout = async (): Promise<void> => {
        try {
            onClose?.()
            await logout()
            navigate('/')
        } catch (err) {
            console.error(err)
            toast.error('Unable to logout.')
        }
    }

    const handleImpersonateStop = async (): Promise<void> => {
        await impersonateStop()
        navigate('/')
    }

    return (
        <Popover
            anchorEl={anchorEl}
            anchorOrigin={{
                horizontal: 'center',
                vertical: 'bottom',
            }}
            keepMounted
            onClose={onClose}
            open={!!open}
            PaperProps={{ sx: { width: 300 } }}
            transitionDuration={0}
            {...other}
        >
            <Box
                sx={{
                    alignItems: 'center',
                    p: 2,
                    display: 'flex',
                }}
            >
                <Box
                    sx={{
                        ml: 1,
                    }}
                >
                    <Typography variant="body1">{user.name}</Typography>
                </Box>
            </Box>
            <Divider />
            <Box sx={{ my: 1 }}>
                <MenuItem component={RouterLink} to="/account">
                    <ListItemIcon>
                        <CogIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1">Account</Typography>
                        }
                    />
                </MenuItem>
                {impersonator && (
                    <MenuItem onClick={handleImpersonateStop}>
                        <ListItemIcon>
                            <EyeIcon fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={
                                <Typography variant="body1">
                                    Impersonation
                                </Typography>
                            }
                        />
                    </MenuItem>
                )}
                <MenuItem onClick={handleLogout}>
                    <ListItemIcon>
                        <LogoutIcon fontSize="small" />
                    </ListItemIcon>
                    <ListItemText
                        primary={
                            <Typography variant="body1">Logout</Typography>
                        }
                    />
                </MenuItem>
            </Box>
        </Popover>
    )
}

AccountPopover.propTypes = {
    anchorEl: PropTypes.any,
    onClose: PropTypes.func,
    open: PropTypes.bool,
}
