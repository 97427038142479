import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'

type Variant = 'light' | 'primary'

interface LogoProps {
    variant?: Variant
}

export const Logo = styled((props: LogoProps) => {
    const { variant, ...other } = props

    const color = variant === 'light' ? '#FFF' : '#0075BF'

    return (
        <svg
            viewBox="0 0 623.62 85.04"
            xmlns="http://www.w3.org/2000/svg"
            {...other}
        >
            <g>
                <path
                    fill={color}
                    d="M354.59,41.37c0,11.67-13.34,21.13-29.8,21.13c-16.46,0-29.8-9.46-29.8-21.13c0-11.67,13.34-21.13,29.8-21.13
                    C341.25,20.24,354.59,29.7,354.59,41.37 M370.88,41.37c0-19.01-20.64-34.42-46.09-34.42S278.7,22.36,278.7,41.37
                    c0,19.01,20.64,34.42,46.09,34.42S370.88,60.38,370.88,41.37"
                />
            </g>
            <path
                fill="#820E64"
                d="M597.58,72.81c0,0.01,0.01,0.02,0.02,0.02h12.91h0.01c4.32,0,7.82-3.5,7.82-7.82v-5.16
	c0-4.32-3.5-7.82-7.82-7.82h-5.18c-4.31,0-7.8,3.49-7.8,7.8L597.58,72.81z"
            />
            <path
                fill={color}
                d="M18.61,39.77V24.34c0-0.01,0.01-0.02,0.02-0.02h5.52v0l46.16,0c4.25,0,7.92,3.32,8,7.57
	c0.09,4.34-3.4,7.91-7.72,7.91H18.63C18.61,39.79,18.61,39.78,18.61,39.77z M61.64,53.29h10.39c11.32,0,21.05-8.59,21.86-19.88
	c0.9-12.57-9.03-23.05-21.4-23.05l-59.34-0.01c0,0-11.19-0.67-11.19,10.54v51.9c0,0.01,0.01,0.02,0.02,0.02h9.92
	c0.29,0.01,0.45,0,0.45,0h6.24c0.01,0,0.02-0.01,0.02-0.02V53.32c0-0.01,0.01-0.02,0.02-0.02h18.28c0,0,0.01,0,0.01,0l31.74,19.53
	c0,0,0.01,0,0.01,0l25.67-0.01c0.02,0,0.03-0.03,0.01-0.04L61.63,53.33C61.61,53.32,61.62,53.29,61.64,53.29z"
            />
            <path
                fill={color}
                d="M281.02,10.46c0-0.01-0.01-0.02-0.02-0.02h-67.53c0,0-16.22-0.09-16.24,13.79c0,0.01,0.01,0.02,0.02,0.02h73.4
	c0,0,10.37-0.35,10.37-11.06V10.46z"
            />
            <path
                fill={color}
                d="M281.02,72.81c0,0.01-0.01,0.02-0.02,0.02h-67.53c0,0-16.22,0.09-16.24-13.79c0-0.01,0.01-0.02,0.02-0.02h73.4
	c0,0,10.37,0.35,10.37,11.06V72.81z"
            />
            <path
                fill={color}
                d="M265.53,41.66c0-2.33,0.26-4.61,0.74-6.85c0-0.01-0.01-0.03-0.02-0.03l-69-0.01c-0.01,0-0.02,0.01-0.02,0.02
	V48.2c0,0.01,0.01,0.02,0.02,0.02l68.94,0.02c0.01,0,0.02-0.01,0.02-0.03C265.76,46.07,265.53,43.89,265.53,41.66z"
            />
            <path
                fill={color}
                d="M587.05,10.44h-25.04c0,0-0.01,0-0.01,0l-43.08,26.91c-0.01,0.01-0.03,0-0.03-0.02l0-22.74h0
	c-0.02-2.29-1.88-4.15-4.17-4.15l-12.5,0c-0.01,0-0.02,0.01-0.02,0.02v62.34c0,0.01,0.01,0.02,0.02,0.02h16.65
	c0.01,0,0.02-0.01,0.02-0.02V54.54l-0.01,0l16.22-10.46c0.01-0.01,0.02,0,0.03,0l31.74,28.74c0,0,0.01,0.01,0.01,0.01h20.2
	c0.02,0,0.03-0.02,0.01-0.04l-39.87-36.52c-0.01-0.01-0.01-0.03,0-0.03l39.85-25.75C587.08,10.47,587.07,10.44,587.05,10.44z"
            />
            <path
                fill={color}
                d="M478.42,10.45L478.42,10.45c-2.29,0.02-4.15,1.88-4.15,4.17l0,58.19c0,0.01,0.01,0.02,0.02,0.02h16.59
	c0.01,0,0.02-0.01,0.02-0.02V10.46c0-0.01-0.01-0.02-0.02-0.02L478.42,10.45z"
            />
            <path
                fill={color}
                d="M463.29,14.57c-0.02-2.29-1.88-4.15-4.17-4.15l-12.47,0c-0.01,0-0.02,0.01-0.02,0.02l0.01,42.63
	c0,0.02-0.02,0.03-0.04,0.02l-51.52-42.66c0,0-0.01-0.01-0.01-0.01h-16.03c-0.01,0-0.02,0.01-0.02,0.02l0,58.23h0
	c0.02,2.29,1.88,4.15,4.17,4.15l12.46-0.01c0.01,0,0.02-0.01,0.02-0.02V30.64c0-0.02,0.02-0.03,0.04-0.02l51.17,42.2
	c0,0,0.01,0,0.01,0h16.38c0.01,0,0.02-0.01,0.02-0.02L463.29,14.57L463.29,14.57z"
            />
            <path
                fill={color}
                d="M187.39,14.58L187.39,14.58c-0.02-2.29-1.88-4.15-4.17-4.15l-12.53,0.01c-0.01,0-0.02,0.01-0.02,0.02v24.36
	c0,0.01-0.01,0.02-0.02,0.02h-50.62c-0.01,0-0.02-0.01-0.02-0.02V10.46c0-0.01-0.01-0.02-0.02-0.02l-16.59,0
	c-0.01,0-0.02,0.01-0.02,0.02v62.34c0,0.01,0.01,0.02,0.02,0.02h16.59c0.01,0,0.02-0.01,0.02-0.02V48.23c0-0.01,0.01-0.02,0.02-0.02
	h50.62c0.01,0,0.02,0.01,0.02,0.02v24.58c0,0.01,0.01,0.02,0.02,0.02h16.69c0.01,0,0.02-0.01,0.02-0.02L187.39,14.58z"
            />
        </svg>
    )
})``

Logo.defaultProps = {
    variant: 'primary',
}

Logo.propTypes = {
    variant: PropTypes.oneOf<Variant>(['light', 'primary']),
}
