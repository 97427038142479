import { CssBaseline, ThemeProvider } from '@mui/material'
import type { FC } from 'react'
import { Toaster } from 'react-hot-toast'
import { useRoutes } from 'react-router-dom'
import { SplashScreen } from './components/splash-screen'
import { ConfirmationProvider } from './contexts/confirmation-context'
import { AuthConsumer } from './contexts/jwt-context'
import { useScrollReset } from './hooks/use-scroll-reset'
import { useSettings } from './hooks/use-settings'
import { routes } from './routes'
import { createTheme } from './theme'

const App: FC = () => {
    const content = useRoutes(routes)
    const { settings } = useSettings()

    useScrollReset()

    return (
        <ThemeProvider
            theme={createTheme({
                direction: settings.direction,
                responsiveFontSizes: settings.responsiveFontSizes,
                mode: settings.theme,
            })}
        >
            <ConfirmationProvider>
                <CssBaseline />
                <Toaster position="top-center" />
                <AuthConsumer>
                    {(auth) =>
                        !auth.isInitialized ? <SplashScreen /> : content
                    }
                </AuthConsumer>
            </ConfirmationProvider>
        </ThemeProvider>
    )
}

export default App
