import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { useAuth } from '../../hooks/use-auth'

interface GuestGuardProps {
    children: ReactNode
}

export const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
    const { isAuthenticated } = useAuth()

    if (isAuthenticated) {
        return <Navigate to="/" />
    }

    return <>{children}</>
}

GuestGuard.propTypes = {
    children: PropTypes.node,
}
