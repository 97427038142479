import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { useState } from 'react'
import { MainNavbar } from './main-navbar'
import { MainSidebar } from './main-sidebar'

interface MainLayoutProps {
    children?: ReactNode
}

const MainLayoutRoot = styled('div')(() => ({
    paddingTop: 80,
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    height: '100%',
    // backgroundImage:
    //     'url("http://staging.rheonik.com.srv1.mpa-hosting.de/fileadmin/_processed_/6/e/csm_Header-Startseite-2560x1080px_Kopie_d3363430f6.jpg")',
    // backgroundPosition: 'center',
    // backgroundAttachment: 'fixed',
    // backgroundSize: 'auto 100%',
}))

export const MainLayout: FC<MainLayoutProps> = ({ children }) => {
    const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false)

    return (
        <MainLayoutRoot>
            <MainNavbar onOpenSidebar={(): void => setIsSidebarOpen(true)} />
            <MainSidebar
                onClose={(): void => setIsSidebarOpen(false)}
                open={isSidebarOpen}
            />
            {children}
        </MainLayoutRoot>
    )
}

MainLayout.propTypes = {
    children: PropTypes.node,
}
