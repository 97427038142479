import { LocalizationProvider } from '@mui/lab'
import AdapterDateFns from '@mui/lab/AdapterDateFns'
import StyledEngineProvider from '@mui/material/StyledEngineProvider'
import deLocale from 'date-fns/locale/de'
import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { HelmetProvider } from 'react-helmet-async'
import { BrowserRouter } from 'react-router-dom'
import 'typeface-rubik'
import App from './App'
import { AuthProvider } from './contexts/jwt-context'
import { SettingsProvider } from './contexts/settings-context'

ReactDOM.render(
    <StrictMode>
        <HelmetProvider>
            <StyledEngineProvider injectFirst>
                <LocalizationProvider
                    dateAdapter={AdapterDateFns}
                    locale={deLocale}
                >
                    <SettingsProvider>
                        <BrowserRouter>
                            <AuthProvider>
                                <App />
                            </AuthProvider>
                        </BrowserRouter>
                    </SettingsProvider>
                </LocalizationProvider>
            </StyledEngineProvider>
        </HelmetProvider>
    </StrictMode>,
    document.getElementById('root')
)
