import axios from '../lib/axios'
import { Account } from '../types/account'

class AccountApi {
    async me(): Promise<Account> {
        const response = await axios.get<Account>('account')
        return response.data
    }

    async impersonator(): Promise<Account> {
        const response = await axios.get<Account>('account/impersonator-info')
        return response.data
    }

    async update(account: Account): Promise<Account> {
        const response = await axios.put<Account>('Account', account)
        return response.data
    }

    async delete(): Promise<void> {
        await axios.delete<Account>('Account')
    }

    async changePassword(password: string): Promise<void> {
        await axios.post('Account/ChangePassword', { password })
    }
}

export const accountApi = new AccountApi()
