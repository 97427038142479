import { jwtDecode } from '../../utils/jwt-decode'
import tokenStorageService from '../../utils/token-storage-service'
import eventBus from '../eventBus'
import axios from './axios'

class TokenService {
    private tokenPromise: Promise<string | null> | null = null

    public getToken = async (): Promise<string | null> => {
        const accessToken = tokenStorageService.getAccessToken()
        if (!accessToken) return null

        const { exp } = jwtDecode(accessToken)
        if (new Date() < new Date(exp * 1e3)) return accessToken

        if (this.tokenPromise) return this.tokenPromise

        this.tokenPromise = this.refreshToken().finally(
            () => (this.tokenPromise = null)
        )
        return this.tokenPromise
    }

    private refreshToken = async (): Promise<string | null> => {
        try {
            const response = await axios.post<string>(
                'auth/refresh-token',
                null,
                {
                    withCredentials: true,
                }
            )
            tokenStorageService.setAccessToken(response.data)
            eventBus.dispatch('token-refreshed', null)
            return response.data
        } catch {
            eventBus.dispatch('logout', null)
            return null
        }
    }
}

const tokenService = new TokenService()
export default tokenService
