import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useEffect, useReducer } from 'react'
import { accountApi } from '../api/account-api'
import { authApi } from '../api/auth-api'
import eventBus from '../lib/eventBus'
import type { Account } from '../types/account'
import { jwtDecode } from '../utils/jwt-decode'
import tokenStorageService from '../utils/token-storage-service'

interface State {
    isInitialized: boolean
    isAuthenticated: boolean
    user: Account | null
    impersonator: Account | null
    permissions: string[] | null
}

export interface AuthContextValue extends State {
    login: (email: string, password: string) => Promise<Account | null>
    logout: () => Promise<void>
    register: (email: string, name: string, token: string) => Promise<void>
    reinit: () => Promise<void>
    impersonate: (userId: string) => Promise<void>
    impersonateStop: () => Promise<void>
}

interface AuthProviderProps {
    children: ReactNode
}

enum ActionType {
    INITIALIZE = 'INITIALIZE',
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
}

type InitializeAction = {
    type: ActionType.INITIALIZE
    payload: {
        isAuthenticated: boolean
        user: Account | null
        impersonator: Account | null
        permissions: string[] | null
    }
}

type LoginAction = {
    type: ActionType.LOGIN
    payload: {
        user: Account
        permissions: string[] | null
    }
}

type LogoutAction = {
    type: ActionType.LOGOUT
}

type Action = InitializeAction | LoginAction | LogoutAction

type Handler = (state: State, action: any) => State

const initialState: State = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
    impersonator: null,
    permissions: null,
}

const handlers: Record<ActionType, Handler> = {
    INITIALIZE: (state: State, action: InitializeAction): State => {
        const { isAuthenticated, user, impersonator, permissions } =
            action.payload

        return {
            ...state,
            isAuthenticated,
            isInitialized: true,
            user,
            impersonator,
            permissions,
        }
    },
    LOGIN: (state: State, action: LoginAction): State => {
        const { user, permissions } = action.payload

        return {
            ...state,
            isAuthenticated: true,
            user,
            permissions,
        }
    },
    LOGOUT: (state: State): State => ({
        ...state,
        isAuthenticated: false,
        user: null,
        impersonator: null,
        permissions: null,
    }),
}

const reducer = (state: State, action: Action): State =>
    handlers[action.type] ? handlers[action.type](state, action) : state

export const AuthContext = createContext<AuthContextValue>({
    ...initialState,
    login: () => Promise.resolve(null),
    logout: () => Promise.resolve(),
    register: () => Promise.resolve(),
    reinit: () => Promise.resolve(),
    impersonate: () => Promise.resolve(),
    impersonateStop: () => Promise.resolve(),
})

export const AuthProvider: FC<AuthProviderProps> = (props) => {
    const { children } = props
    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        const initialize = async (): Promise<void> => {
            await init()
        }

        initialize()
    }, [])

    useEffect(() => {
        eventBus.on('logout', async () => {
            await logout()
        })
        eventBus.on('token-refreshed', async () => {
            await init()
        })
    }, [])

    const init = async (): Promise<void> => {
        try {
            const accessToken = await tokenStorageService.getAccessToken()

            if (accessToken) {
                const { impersonator, ...claims } = jwtDecode(accessToken)
                const permissions =
                    claims['https://rheonik.de/claims/permission'] || null
                const role: string | null =
                    claims[
                        'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
                    ] || null

                const userInfo: Account = await accountApi.me()
                userInfo.role = role

                const impersonatorInfo: Account | null = impersonator
                    ? await accountApi.impersonator()
                    : null

                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: true,
                        user: userInfo,
                        impersonator: impersonatorInfo,
                        permissions,
                    },
                })
            } else {
                dispatch({
                    type: ActionType.INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                        impersonator: null,
                        permissions: null,
                    },
                })
            }
        } catch (err) {
            console.error(err)
            dispatch({
                type: ActionType.INITIALIZE,
                payload: {
                    isAuthenticated: false,
                    user: null,
                    impersonator: null,
                    permissions: null,
                },
            })
        }
    }

    const login = async (email: string, password: string): Promise<Account> => {
        const accessToken = await authApi.login({ email, password })
        tokenStorageService.setAccessToken(accessToken)

        const { ...claims } = jwtDecode(accessToken)
        const permissions =
            claims['https://rheonik.de/claims/permission'] || null
        const role: string | null =
            claims[
                'http://schemas.microsoft.com/ws/2008/06/identity/claims/role'
            ] || null

        const user: Account = await accountApi.me()
        user.role = role

        dispatch({
            type: ActionType.LOGIN,
            payload: {
                user,
                permissions,
            },
        })

        return user
    }

    const logout = async (): Promise<void> => {
        try {
            await authApi.logout()
        } finally {
            tokenStorageService.clearToken()
            dispatch({ type: ActionType.LOGOUT })
        }
    }

    const register = async (
        email: string,
        name: string,
        token: string
    ): Promise<void> => {
        await authApi.register({ email, name, token })
    }

    const reinit = async () => {
        await init()
    }

    const impersonate = async (userId: string): Promise<void> => {
        const accessToken = await authApi.impersonate(userId)
        tokenStorageService.setAccessToken(accessToken)

        await init()
    }

    const impersonateStop = async (): Promise<void> => {
        const accessToken = await authApi.impersonateStop()
        tokenStorageService.setAccessToken(accessToken)

        await init()
    }

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout,
                register,
                reinit,
                impersonate,
                impersonateStop,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}

AuthProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const AuthConsumer = AuthContext.Consumer
