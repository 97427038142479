import PropTypes from 'prop-types'
import type { FC, ReactNode } from 'react'
import { createContext, useRef, useState } from 'react'
import ConfirmationDialog, {
    ConfirmationOptions,
} from '../components/confirmation-dialog'

interface ConfirmationProviderProps {
    children: ReactNode
}

export const ConfirmationContext = createContext<
    // we will pass the openning dialog function directly to consumers
    (options: ConfirmationOptions) => Promise<void>
>(Promise.reject)

export const ConfirmationProvider: FC<ConfirmationProviderProps> = ({
    children,
}) => {
    const [confirmationState, setConfirmationState] =
        useState<ConfirmationOptions | null>(null)

    const awaitingPromiseRef = useRef<{
        resolve: () => void
        reject: () => void
    }>()

    const openConfirmation = (options: ConfirmationOptions) => {
        setConfirmationState(options)
        return new Promise<void>((resolve, reject) => {
            awaitingPromiseRef.current = { resolve, reject }
        })
    }

    const handleClose = () => {
        if (confirmationState?.catchOnCancel && awaitingPromiseRef.current) {
            awaitingPromiseRef.current.reject()
        }

        setConfirmationState(null)
    }

    const handleSubmit = () => {
        if (awaitingPromiseRef.current) {
            awaitingPromiseRef.current.resolve()
        }

        setConfirmationState(null)
    }

    return (
        <>
            <ConfirmationContext.Provider value={openConfirmation}>
                {children}
            </ConfirmationContext.Provider>

            <ConfirmationDialog
                open={Boolean(confirmationState)}
                onSubmit={handleSubmit}
                onClose={handleClose}
                {...confirmationState}
            />
        </>
    )
}

ConfirmationProvider.propTypes = {
    children: PropTypes.node.isRequired,
}

export const ConfirmationConsumer = ConfirmationContext.Consumer
