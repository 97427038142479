import {
    AppBar,
    Avatar,
    Box,
    ButtonBase,
    Container,
    IconButton,
    Link,
    Toolbar,
} from '@mui/material'
import PropTypes from 'prop-types'
import { FC, useRef, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { useAuth } from '../hooks/use-auth'
import { Menu as MenuIcon } from '../icons/menu'
import { UserCircle as UserCircleIcon } from '../icons/user-circle'
import { AccountPopover } from './account-popover'
import { Logo } from './logo'

interface MainNavbarProps {
    onOpenSidebar?: () => void
}

const AccountButton = () => {
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const [openPopover, setOpenPopover] = useState<boolean>(false)

    const handleOpenPopover = (): void => {
        setOpenPopover(true)
    }

    const handleClosePopover = (): void => {
        setOpenPopover(false)
    }

    return (
        <>
            <Box
                component={ButtonBase}
                onClick={handleOpenPopover}
                ref={anchorRef}
                sx={{
                    alignItems: 'center',
                    display: 'flex',
                    ml: 2,
                }}
            >
                <Avatar
                    sx={{
                        height: 40,
                        width: 40,
                    }}
                >
                    <UserCircleIcon fontSize="small" />
                </Avatar>
            </Box>
            <AccountPopover
                anchorEl={anchorRef.current}
                onClose={handleClosePopover}
                open={openPopover}
            />
        </>
    )
}

export const MainNavbar: FC<MainNavbarProps> = (props) => {
    const { onOpenSidebar } = props
    const { user, ...auth } = useAuth()

    return (
        <AppBar
            elevation={0}
            sx={{
                backgroundColor: 'primary',
                boxShadow: '0 0 5px rgb(0 0 0 / 50%)',
                color: 'text.secondary',
            }}
        >
            <Container maxWidth="lg">
                <Toolbar disableGutters sx={{ height: 80 }}>
                    <Link component={RouterLink} to="/">
                        <Logo
                            variant="light"
                            sx={{
                                width: 210,
                                height: 30,
                            }}
                        />
                    </Link>
                    <Box sx={{ flexGrow: 1 }} />
                    <IconButton
                        onClick={onOpenSidebar}
                        sx={{
                            color: 'white',
                            display: {
                                md: 'none',
                            },
                        }}
                    >
                        <MenuIcon fontSize="small" />
                    </IconButton>
                    {!auth.isAuthenticated && (
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: {
                                    md: 'flex',
                                    xs: 'none',
                                },
                            }}
                        >
                            <Link
                                color="white"
                                component={RouterLink}
                                sx={{ ml: 2 }}
                                to="/authentication/login"
                                underline="none"
                                variant="subtitle2"
                            >
                                Login
                            </Link>

                            <Link
                                color="white"
                                component={RouterLink}
                                sx={{ ml: 2 }}
                                to="/authentication/register"
                                underline="none"
                                variant="subtitle2"
                            >
                                Register
                            </Link>
                        </Box>
                    )}
                    {auth.isAuthenticated && (
                        <Box
                            sx={{
                                alignItems: 'center',
                                display: {
                                    md: 'flex',
                                    xs: 'none',
                                },
                            }}
                        >
                            <Link
                                color="white"
                                component={RouterLink}
                                sx={{ ml: 2 }}
                                to="/orders"
                                underline="none"
                                variant="subtitle2"
                            >
                                Orders
                            </Link>
                            {user.role === 'Admin' && (
                                <Link
                                    color="white"
                                    component={RouterLink}
                                    sx={{ ml: 2 }}
                                    to="/customers"
                                    underline="none"
                                    variant="subtitle2"
                                >
                                    Customers
                                </Link>
                            )}
                            <AccountButton />
                        </Box>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    )
}

MainNavbar.propTypes = {
    onOpenSidebar: PropTypes.func,
}
