import { Box, Button, FormHelperText, TextField } from '@mui/material'
import { useFormik } from 'formik'
import type { FC } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useAuth } from '../../hooks/use-auth'
import { useMounted } from '../../hooks/use-mounted'

export const JWTLogin: FC = (props) => {
    const isMounted = useMounted()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const { login } = useAuth()
    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            submit: null,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email('Must be a valid email')
                .max(255)
                .required('Email is required'),
            password: Yup.string().max(255).required('Password is required'),
        }),
        onSubmit: async (values, helpers): Promise<void> => {
            try {
                const user = await login(values.email, values.password)

                if (isMounted()) {
                    console.log(user)
                    const returnUrl =
                        searchParams.get('returnUrl') ||
                        user.role === 'Customer'
                            ? '/orders'
                            : user.role === 'Admin'
                            ? '/customers'
                            : '/'
                    navigate(returnUrl)
                }
            } catch (err) {
                console.error(err)

                if (isMounted()) {
                    helpers.setStatus({ success: false })
                    helpers.setErrors({ submit: err.message })
                    helpers.setSubmitting(false)
                }
            }
        },
    })

    return (
        <form noValidate onSubmit={formik.handleSubmit} {...props}>
            <TextField
                autoFocus
                error={Boolean(formik.touched.email && formik.errors.email)}
                fullWidth
                helperText={formik.touched.email && formik.errors.email}
                label="Email Address"
                margin="normal"
                name="email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="email"
                value={formik.values.email}
            />
            <TextField
                error={Boolean(
                    formik.touched.password && formik.errors.password
                )}
                fullWidth
                helperText={formik.touched.password && formik.errors.password}
                label="Password"
                margin="normal"
                name="password"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                type="password"
                value={formik.values.password}
            />
            {formik.errors.submit && (
                <Box sx={{ mt: 3 }}>
                    <FormHelperText error>
                        {formik.errors.submit}
                    </FormHelperText>
                </Box>
            )}
            <Box sx={{ mt: 2 }}>
                <Button
                    disabled={formik.isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                >
                    Log In
                </Button>
            </Box>
        </form>
    )
}
