import {
    Box,
    Button,
    Container,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useTheme } from '@mui/material/styles'
import type { FC } from 'react'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { Link as RouterLink } from 'react-router-dom'
import { gtm } from '../lib/gtm'

const AuthorizationRequired: FC = () => {
    const theme = useTheme()
    const mobileDevice = useMediaQuery(theme.breakpoints.down('sm'))

    useEffect(() => {
        gtm.push({ event: 'page_view' })
    }, [])

    return (
        <>
            <Helmet>
                <title>Error: Authorization Required | Material Kit Pro</title>
            </Helmet>
            <Box
                component="main"
                sx={{
                    alignItems: 'center',
                    backgroundColor: 'background.paper',
                    display: 'flex',
                    flexGrow: 1,
                    py: '80px',
                }}
            >
                <Container maxWidth="lg">
                    <Typography
                        align="center"
                        variant={mobileDevice ? 'h4' : 'h1'}
                    >
                        401: Authorization required
                    </Typography>
                    <Typography
                        align="center"
                        color="textSecondary"
                        sx={{ mt: 0.5 }}
                        variant="subtitle2"
                    >
                        You either tried some shady route or you came here by
                        mistake. Whichever it is, try using the navigation.
                    </Typography>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                        }}
                    >
                        <Box
                            alt="Under development"
                            component="img"
                            src={`/static/error/error401_${theme.palette.mode}.svg`}
                            sx={{
                                height: 'auto',
                                maxWidth: '100%',
                                width: 400,
                            }}
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            mt: 6,
                        }}
                    >
                        <Button
                            color="primary"
                            component={RouterLink}
                            to="/"
                            variant="outlined"
                        >
                            Back to Homepage
                        </Button>
                    </Box>
                </Container>
            </Box>
        </>
    )
}

export default AuthorizationRequired
