function stringsToNull(body: any) {
    if (body === null || body === undefined || typeof body !== 'object')
        return body

    Object.keys(body).forEach((key) => {
        const value = body[key]
        if (value === '') body[key] = null
    })

    return body
}

export default stringsToNull
