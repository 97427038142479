import axios from '../lib/axios'

class AuthApi {
    async login({
        email,
        password,
    }: {
        email: string
        password: string
    }): Promise<string> {
        const response = await axios.post<string>(
            'auth/login',
            {
                email,
                password,
            },
            {
                withCredentials: true,
            }
        )
        return response.data
    }

    async logout(): Promise<void> {
        await axios.post<string>(
            'auth/revoke-token',
            {},
            {
                withCredentials: true,
            }
        )
    }

    async register({
        email,
        name,
        token,
    }: {
        email: string
        name: string
        token: string
    }): Promise<void> {
        await axios.post('auth/register', { email, name, token })
    }

    async forgotPassword(email: string): Promise<void> {
        await axios.post<void>('auth/forgot-password', { email })
    }

    async resetPassword({
        token,
        customerId,
        password,
    }: {
        token: string | null
        customerId: string | null
        password: string
    }): Promise<void> {
        await axios.post<void>('auth/reset-password', {
            token,
            customerId,
            password,
        })
    }

    async impersonate(impersonateeId: string): Promise<string> {
        const response = await axios.post<string>(
            'auth/impersonate',
            {
                impersonateeId,
            },
            {
                withCredentials: true,
            }
        )
        return response.data
    }

    async impersonateStop(): Promise<string> {
        const response = await axios.post<string>(
            'auth/impersonate-stop',
            null,
            {
                withCredentials: true,
            }
        )
        return response.data
    }
}

export const authApi = new AuthApi()
